import { template as template_ccc004109b6348e189865fb31ad88d34 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_ccc004109b6348e189865fb31ad88d34(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_ccc004109b6348e189865fb31ad88d34(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_ccc004109b6348e189865fb31ad88d34(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
