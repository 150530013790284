import { template as template_056f2d21d0b54bb6bbfe722a6d7254f5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_056f2d21d0b54bb6bbfe722a6d7254f5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
