import { template as template_fdbbb6eb08c342b3b91f6f7b3730050a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_fdbbb6eb08c342b3b91f6f7b3730050a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
